@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
$brand-font-folder: '/assets/fonts';
$icon-font-path: '/assets/icons/fonts';
$images-path: '/assets/images';

@import 'styles/jt-dark-theme';
@import 'styles/jt-light-theme';

html, body {
  margin: 0;
  height: 100%;
}

body {
  a:focus {
    box-shadow: none;
  }
}

.mat-tooltip.tooltip {
    background-color: rgb(0, 139, 104);
    font-size: 16px;
}


.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.custom-tooltip p:first-of-type {
    font-weight: bold;
}

.form-description{
    max-width: 250px;
}

.mat-tooltip.key-tooltip {
    font-size: 14px;
    white-space: pre-line;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/*
 * material theme overrides
 */
//html {
//  --mat-toolbar-container-background-color: rgba(0, 0, 0, 0.57);
//    //#B3ADA7;
//  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
//}
//
//.mat-mdc-raised-button.mat-primary {
//  --mdc-protected-button-container-color: #00a0d1;
//}
