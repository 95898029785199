@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();
mat.$theme-ignore-duplication-warnings:disabled;

// Define the dark theme.
$primary: mat.define-palette(mat.$light-blue-palette);
$accent:  mat.define-palette(mat.$green-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette);

$jt-dark-theme: mat.define-dark-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  typography: mat.define-typography-config(),
));

.jt-dark-theme {
  @include mat.core-theme($jt-dark-theme);
  @include mat.all-component-themes(map.merge($jt-dark-theme, (
    typography: mat.define-typography-config()
  )));

  // json viewer options
  --ngx-json-key: #ffffff;
}
